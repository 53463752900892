<template>
	<div class="auditionlist">
		<div class="auditionbanner">
			<img :src="banner.thumb" />
		</div>
		<div class="mian">
			<div class="public_title">
				<div class="public_title_name">试听专区</div>
			</div>
			<div class="list">
				<div class="video_list video_lists clearfix" v-if="list.length > 0">
					<!-- wow animate__animated animate__fadeInUp -->
					<div class="daily_ul" v-for="(row, i) in list" :key="i">
						<div class="video_li" :style="{ animationDelay: 0.1 * index + 's' }" v-for="(item, index) in row" :key="index" @click="toDetail(item.id)">
							<div class="video_li_box">
								<img v-lazy="item.thumb" alt="" />
								<div class="video_li_mask"></div>
								<div class="video_li_play">
									<img src="../assets/images/icon_play@2x.png" alt="" />
								</div>
							</div>

							<div class="video_li_reading clearfix">
								<div class="fl">
									<div class="video_li_name">{{ item.title }}</div>
								</div>
								<div class="fr clearfix">
									<div class="fl clearfix video_li_reading_k">
										<img class="fl" src="../assets/images/yulan.svg" alt="" />
										<span class="fl">{{ item.browse_num || 0 }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-empty v-else description="暂无数据"></el-empty>
				<page v-if="total>16" :page="page" :limit="limit" :total="total" ref="page"></page>
			</div>
			<div class="mian audmain">
				<div class="provide_list clearfix">
					<div class="fl provide_li" @click="toVipbuyFun">
						<img src="../assets/images/xtspjc.svg" alt="" />
						<h5>海量系统视频教程</h5>
						<p>不同风格字体设计视频全覆盖</p>
					</div>
					<div class="fl provide_li" @click="toVipbuyFun">
						<img src="../assets/images/zydp.svg" alt="" />
						<h5>作业100%点评</h5>
						<p>每个视频均可提交作业</p>
					</div>
					<div class="fl provide_li" @click="toVipbuyFun">
						<img src="../assets/images/sybz.svg" alt="" />
						<h5>素材商用保障</h5>
						<p>所有字体素材均可商用</p>
					</div>
					<div class="fl provide_li" @click="toVipbuyFun">
						<img src="../assets/images/hlscxz.svg" alt="" />
						<h5>海量素材下载</h5>
						<p>精选标题设计素材 行业涉略全面</p>
					</div>
					<div class="fl provide_li" @click="toVipbuyFun">
						<img src="../assets/images/sjbzzs.svg" alt="" />
						<h5>设计步骤展示</h5>
						<p>教程和字体素材源文件均保留步骤</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 侧边导航 -->
		<sidebar ref="downtotal"></sidebar>

	</div>
</template>

<script>
	import sidebar from "../components/sidebar.vue";
	import page from "../components/page.vue";
	export default {
		name: "auditionlist",
		components: {
			sidebar,
			page,
		},
		data() {
			return {
				banner: {}, //banner

				video_total: 0, //作品总数
				keywords: "", //搜索内容
				unfold: true,
				themeList: [], //分类


				list: [],
				projectList: [],
				videoList: [],
				emptyShow: false,

				isShow: false, //收藏夹显示

				// 分页
				total: 0, //总数
				page: 1, //页数
				limit: 80, //每页个数

				// 收藏参数
				type: "", //类型
				collId: "", //收藏id

				// 接收参数
				navId: "",
				navIndex: 0,
				navName: "",
				font_style: 0,
				font_cate: 0,
				scroll: 0,
				series: 0
			};
		},
		mounted() {
			this.$parent.routerIndex = 10;
			this.$parent.showIndex = 1;
			this.bannerOneban();

			this.listFun();
		},
		methods: {
			// 获取内部banner、
			bannerOneban() {
				var that = this;
				this.$api.POST(this.$face.bannerOneban, {
					location: 17
				}, function(res) {
					that.banner = res.data;
				});
			},

			// 获取列表
			listFun() {
				var that = this;
				var params = {
					page: this.page,
					limit: this.limit
				};
				if (this.$refs.page && this.page == 1) {
					this.page == 1
					this.$refs.page.currentPage = 1;
				}
				this.$api.POST(this.$face.auditionIndex, params, function(res) {
					
					// that.list = res.data.lists;
					that.list = that.$utile.listData(res.data.lists);
					
					console.log("that.list",that.list)
					that.total = res.data.total;

					that.emptyShow = true;
				});
			},
			// 查看详情
			toDetail(id) {
				localStorage.setItem("clearAll", 2);
				//this.$router.push({ name: "videoDetail", query: { id: id } });
				let routeData = this.$router.resolve({
					name: "videoDetail",
					query: {
						id: id
					},
				});
				window.open(routeData.href, "_blank");
			},
			toVipbuyFun() {
				//   this.$router.push({
				//     name: "vip",
				//   });
				let routeData = this.$router.resolve({
					name: "vip",
				});
				window.open(routeData.href, "_blank");
			},

		},

		activated() {
			this.$nextTick(() => {
				this.$parent.routerIndex = 10;
				this.$parent.showIndex = 1;
				if (localStorage.getItem('clearAll') != 2) {
					this.page = 1;
					if (this.$refs.page) {
						this.$refs.page.currentPage = 1;
					}
					this.listFun()
				}
				localStorage.removeItem('clearAll')
			});
		}

	};
</script>

<style scoped>
	.public_title{
		margin-top: 30px;
	}
	.audmain{
		padding: 0 !important;
	}
	.auditionbanner {
		width: 100%;
		position: relative;
		background-color: #b5dc48;
		margin-bottom: 15px;
		overflow: hidden;
		min-height: 200px;
	}

	.auditionbanner img {
		width: 100%;
	}

	.el-empty {
		position: relative;
		z-index: 97;
		background-color: #f6f6f6;
	}

	.loading {
		width: 100%;
		height: 800px;
		text-align: center;
		font-size: 20px;
		color: #999999;
		text-align: center;
		position: absolute;
		top: 50px;
		left: 0;
		z-index: 1;
		padding-top: 100px;
		box-sizing: border-box;
	}

	.loading img {
		width: 24px;
		height: 24px;
		display: inline-block !important;
		vertical-align: middle;
		animation: fadeum 5s infinite;
	}

	@keyframes fadeum {
		0 {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.auditionlist {
		background-color: #f6f6f6;
	}

	.material_screen {
		width: 100%;
		background: #ffffff;
		/* min-height: 458px; */
	}

	.inner_nav {
		padding: 27px 0 17px;
	}

	.inner_nav_l {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}

	.inner_nav_r {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 12px;
		letter-spacing: 0px;
		color: #999999;
	}

	.inner_nav_r span {
		color: #5957ff;
		font-weight: bold;
		font-size: 16px;
	}

	.inner_banner {
		display: block;
		margin-bottom: 32px;
	}

	.inner_banner img {
		width: 100%;
	}

	.material_class_list {
		border-bottom: 1px solid #eeeeee;
		transition: max-height 0.6s;
		max-height: 400px;
		overflow: hidden;
	}

	.material_class_list.min {
		max-height: 0;
		overflow: hidden;
	}

	.material_class_item {
		position: relative;
		margin-bottom: 17px;
	}

	.class_list_title {
		position: absolute;
		top: 0;
		left: 0;
		height: 30px;
		line-height: 30px;
		color: #999999;
	}

	.class_list {
		width: 100%;
		padding-left: 52px;
		padding-right: 100px;
		box-sizing: border-box;
	}

	.class_list.mini {
		height: 40px;
		overflow: hidden;
	}

	.class_list>div {
		height: 30px;
		line-height: 28px;
		font-size: 14px;
		text-align: center;
		color: #333;
		/* border: solid 1px transparent; */
		box-sizing: border-box;
		margin: 0 2px;
		margin-bottom: 10px;
		padding: 0 10px;
		border-radius: 10px;
		cursor: pointer;
	}

	.class_list>div:hover {
		color: #221eff;
	}

	.class_list>div.active {
		color: #221eff;
		background: rgba(235, 235, 255, 0.6);
	}

	.class_list_unfold {
		position: absolute;
		height: 30px;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #5957ff;
		cursor: pointer;
	}

	.class_list_unfold img {
		margin-left: 4px;
	}

	.sort_l {
		font-size: 14px;
		color: #333333;
		line-height: 54px;
	}

	.sort_l>div:nth-child(1) {
		color: #999999;
	}

	.sort_l_box {
		position: relative;
		height: 54px;
		display: flex;
		align-items: center;
	}

	.sort_l_s {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 0 12px;
		margin-right: 17px;
		height: 30px;
		border-radius: 10px;
	}

	.sort_l_s.active {
		color: #221eff;
		background: rgba(235, 235, 255, 0.6);
	}

	.sort_l_s:hover {
		color: #221eff;
	}

	.sort_l_ul {
		width: 100%;
		position: absolute;
		top: 54px;
		left: 0;
	}

	.sort_l_ul>div {
		text-align: center;
		background: #fff;
		cursor: default;
		transition: 0.3s;
	}

	.sort_l_ul>div:hover {
		background: #f6f7f9;
	}

	.sort_r {
		font-size: 14px;
		color: #333333;
		line-height: 54px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.sort_r:hover {
		color: #5957ff;
	}

	.sort_r img {
		margin: 0 6px 0 8px;
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.sort_r.min img {
		transform: rotate(0);
	}

	.list {
		padding-bottom: 5px;
		min-height: 500px;
		position: relative;
	}

	/* 视频教程 */
	.video_list {
		margin: 28px -13.33px 24px;
		position: relative;
		z-index: 9;
		background-color: #f6f6f6;
		min-height: 200px;
	}

	.video_lists {
		display: flex;
	}

	.daily_ul {
		margin: 0 13.33px;
		width: calc(100% / 4 - 26.66px);
	}

	.video_li {
		width: 330px;
		margin: 0 0 24px;
		background: #fff;
		border-radius: 10px;
		padding: 15px;
		box-sizing: border-box;
	}

	.video_lis {
		margin: 0 13.33px 24px;
	}

	.video_li_box {
		width: 100%;
		/* height: 198px; */
		border-radius: 10px;
		position: relative;
		overflow: hidden;
	}

	.video_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.video_li_play {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 101;
		margin-left: -37.5px;
		margin-top: -37px;
		width: 74px;
		height: 75px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		opacity: 0;
	}

	.video_li:hover .video_li_mask {
		opacity: 1;
	}

	.video_li:hover .video_li_play {
		opacity: 1;
	}

	.video_li_name {
		/* height: 52px;
    line-height: 49px; */
		font-size: 17px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #333333;
		width: 230px;
		overflow: hidden;
		/* 第二步：让文本不会换行， 在同一行继续 */
		white-space: nowrap;
		/* 第三步：用省略号来代表未显示完的文本 */
		text-overflow: ellipsis;
	}

	.video_li_reading {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #999999;
		margin-top: 10px;
	}

	.video_li_reading img {
		width: auto;
		height: 20px;
		margin-right: 4px;
	}

	.video_li_reading span {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #999999;
	}

	.video_li_reading_k {
		margin-right: 16px;
	}

	/* 专题 */
	.project {
		margin: 28px -13.33px 40px;
	}

	.project_li {
		width: 330px;
		height: 300px;
		margin: 0 13.33px 30px;
		padding-top: 15px;
		background: url(../assets/images/bg_tjzt2x.png) no-repeat;
		background-size: 311px auto;
		background-position: center top;
	}

	.project_li_box {
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		width: 330px;
		height: 300px;
	}

	.project_li_box>img,
	.video_li_box>img,
	.daily_li_box>img {
		/* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */

		width: 100%;
		height: auto;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_hot {
		position: absolute;
		top: 14px;
		left: -10px;
		width: 62px;
		height: 40px;
		background-color: #df0003;
		border-radius: 10px;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 40px;
		text-align: center;
		letter-spacing: 0px;
		color: #ffffff;
		z-index: 101;
		transition: 0.5s;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.project_li:hover .project_li_hot {
		left: 15px;
		padding-left: 0px;
	}

	.project_li_buttom {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 45px;
		text-align: left;
		padding: 0 16px;
		box-sizing: border-box;
		line-height: 45px;
		color: #fff;
		font-size: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-border-radius: 0;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.4);
		opacity: 1;
		z-index: 33;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_mask {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 0;
		z-index: 34;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		cursor: default;
	}

	.project_li_collect {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 14px;
		right: 15px;
		z-index: 100;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		cursor: pointer;
	}

	.project_li_collect img {
		width: 100%;
		height: 100%;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}

	.project_li_collect:hover img {
		opacity: 0.8;
	}

	.project_li:hover .project_li_box>img,
	.video_li:hover .video_li_box>img,
	.daily_li:hover .daily_li_box>img {
		transform: scale(1.08);
	}

	.project_li:hover .project_li_buttom {
		opacity: 0;
	}

	.project_li:hover .project_li_mask,
	.project_li:hover .project_li_collect {
		opacity: 1;
	}
	
	.provide_list {
		margin: 51px -20px;
	}
	
	.provide_li {
		width: 255px;
		margin: 0 15px;
		background-color: #ffffff;
		border-radius: 10px;
		padding-top: 28px;
		padding-bottom: 30px;
		text-align: center;
		cursor: pointer;
	}
	
	.provide_li img {
		width: 40px;
		height: 40px;
		margin: 0 auto 20px;
	}
	
	.provide_li h5 {
		font-size: 19px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #282828;
		margin-bottom: 12px;
	}
	
	.provide_li p {
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #666666;
	}

	/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

	.sidebar_r {
		right: 2%;
		left: auto;
	}

	.video_lis .video_li_box {
		height: 198px;
	}

	.video_lis .video_thumb {
		position: relative;
		height: 198px;
		transition: all 0.3s ease-in-out;
	}

	.video_lis .video_thumb>img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: auto;
	}

	.video_lis:hover .video_thumb {
		transform: scale(1.08);
	}
</style>
